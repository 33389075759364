import React from "react";
import "./App.css";
import CourseMigration from "./CourseMigration";

function App() {
  return (
    <div className="App">
      <CourseMigration />
    </div>
  );
}

export default App;
