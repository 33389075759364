import React, { useCallback, useEffect, useRef } from "react";
import { Divider, Timeline, Typography } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import "./CourseMigration.css";

const { Title } = Typography;

export enum MigrationStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED",
}

const iconMap = {
  [MigrationStatus.COMPLETED]: (
    <CheckCircleOutlined className="timeline-clock-icon" />
  ),
  [MigrationStatus.IN_PROGRESS]: <SyncOutlined spin className="sync-icon" />,
  [MigrationStatus.FAILED]: (
    <CloseCircleOutlined className="timeline-clock-icon" />
  ),
};

const iconColorMap = {
  [MigrationStatus.COMPLETED]: "green",
  [MigrationStatus.IN_PROGRESS]: "blue",
  [MigrationStatus.FAILED]: "red",
};

const endpoint =
  "https://main--gh-fns-perf.netlify.app/api/courses-fetch-migration-log";

const CourseMigration: React.FC = () => {
  const isInit = React.useRef(false);

  const [migrationLogs, setMigrationLogs] = React.useState<{
    [key: string]: any[];
  }>({});
  const [hasError, setHasError] = React.useState(false);
  const courseId = useRef(
    new URLSearchParams(document.location.search).get("courseId")
  );
  const migrationRef = useRef(
    new URLSearchParams(document.location.search).get("migrationRef")
  );

  const toStepItem = useCallback(
    (log: any) => {
      const { action, status, createdAt, error, refId, courseId, details } =
        log;

      let extras: any[] = [];
      if (details) {
        extras = Object.keys(details).map((key) => (
          <p key={key}>
            {key}: {details[key]}
          </p>
        ));
      }

      const isError = status === MigrationStatus.FAILED || error;
      if (hasError === false && isError) {
        setHasError(true);
      }

      const icon = (iconMap as any)[status];

      const label = <span className={isError && "ErrorText"}>{createdAt}</span>;
      const referenceId = refId || courseId;

      return {
        dot: icon,
        label: label,
        children: (
          <React.Fragment key={`${label}-${action}-${referenceId}`}>
            <p className={isError && "ErrorText"}>{action}</p>
            <p>Reference Id: {referenceId}</p>
            {isError && <p>Error: {error}</p>}
            {extras.map((extra) => extra)}
          </React.Fragment>
        ),
        color: (iconColorMap as any)[status],
      };
    },
    [hasError]
  );

  const fetchLogs = useCallback(async () => {
    if (!courseId.current && !migrationRef.current) {
      return;
    }

    const url = new URL(endpoint);
    courseId.current
      ? url.searchParams.append("courseId", courseId.current)
      : url.searchParams.append("migrationRef", migrationRef.current as string);

    const response = await fetch(url);
    const result = await response.json();

    if (Object.keys(result).length > 0) {
      const allSteps: { [key: string]: any[] } = {};

      Object.keys(result).forEach((migrationRef) => {
        const migrationsByRef = result[migrationRef] as {
          [key: string]: any[];
        };

        const steps = Object.keys(migrationsByRef).map((id) => {
          return migrationsByRef[id].map((log: any) => toStepItem(log));
        });
        allSteps[migrationRef] = steps;
      });

      setMigrationLogs(allSteps);
    }
  }, [courseId, migrationRef, toStepItem]);

  const getSteps = (logs: any[]) => {
    if (logs.length === 0) {
      return null;
    }
    return logs.map((steps, index) => {
      return (
        <React.Fragment key={index}>
          <Timeline style={{ marginTop: 30 }} mode="left" items={steps} />
          <Divider />
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
      fetchLogs();
    }
  }, [fetchLogs]);

  if (!courseId.current && !migrationRef.current) {
    return <p>Either course id or migrationRef is required!</p>;
  }

  let migrationResult = null;
  if (Object.keys(migrationLogs).length > 0) {
    migrationResult = hasError ? (
      <Title level={3} type="warning">
        <ExclamationCircleOutlined key="copy-icon" />
        &nbsp;Migration completed with some errors!
      </Title>
    ) : (
      <Title level={3} type="success">
        <SmileOutlined key="copy-icon" />
        &nbsp;Migration completed successfully.
      </Title>
    );
  }

  return (
    <>
      {migrationResult}
      {Object.keys(migrationLogs).map((migrationRef) => {
        return (
          <>
            <Title level={4}>Migration Reference: {migrationRef}</Title>
            {getSteps(migrationLogs[migrationRef])}
          </>
        );
      })}
    </>
  );
};

export default CourseMigration;
